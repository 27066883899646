<template>
  <div>
    <a-card title="标签(标签库的编辑、删除不会影响其他楼盘的已有标签)" :bordered="false" class="card">
      <span :style="{ marginRight: 8 }" class="tag_title">楼盘标签
        <a-tag color="orange" style="margin-right: 0; padding: 0 5px;">{{ selectedTags.length }}/10</a-tag> :</span>
      <template v-for="tag in selectedTags" class="myTag">
        <a-checkable-tag
          :key="tag.id"
          :checked="selectedTags.indexOf(tag) > -1"
          @change="checked => selectChange(tag, checked)"
          style="cursor: pointer;"
        >
          {{ tag }}
        </a-checkable-tag>
      </template>
      <a-button type="dashed" :loading="loading" @click="saveTag(selectedTags)">
        保存
      </a-button>
      <br />
      <br />
      <span :style="{ marginRight: 8 }" class="tag_title">标签库 :</span>
      <span v-for="tag in tags" :key="tag.id" @mouseenter="enterTag(tag)" class="myTag">
        <a-dropdown>
          <a-checkable-tag :key="tag.id" @change="checked => handleChange(tag, checked)">
            {{ tag }}
          </a-checkable-tag>
          <a-menu slot="overlay" @click="handleTagClick">
            <a-menu-item key="edit"> <a-icon type="edit" />编辑</a-menu-item>
            <a-menu-item key="del"> <a-icon type="delete" />删除</a-menu-item>
          </a-menu>
          <a-button style="margin-left: 8px"> Button <a-icon type="down" /> </a-button>
        </a-dropdown>
      </span>
      <a-button type="dashed" :loading="handleLoading" @click="addTag">
        新建标签
      </a-button>
    </a-card>
    <create-form
      ref="createModal"
      :visible="visible"
      :loading="handleLoading"
      :model="mdl"
      @cancel="handleCancel"
      @ok="handleOk"
    />
  </div>
</template>

<script>
import { addLabels, findAllByType, delLabels, editLabels } from '@/api/labels'
import { editHouses, findByIdHouse } from '@/api/house'
import CreateForm from './modules/CreateLabel'
export default {
  components: {
    CreateForm
  },
  data() {
    return {
      noticeTitle: '标签管理',
      houseId: '',
      currentTag: null,
      mdl: null,
      tags: [],
      tagsInfo: [],
      // edit
      visible: false,
      handleLoading: false,
      selectedTags: [],
      // add tag
      inputVisible: false,
      // submit
      loading: false
    }
  },
  created() {
    this.houseId = this.$route.query.id
  },
  mounted() {
    this.load()
  },
  methods: {
    load() {
      this.getAllLabel()
      this.getHouseLabel()
    },
    getAllLabel() {
      const requestParameters = {
        type: 'house'
      }
      findAllByType(requestParameters).then(res => {
        console.log(res, 'labels')
        this.tagsInfo = res._embedded.labels
        this.tags = []
        res._embedded.labels.forEach(item => {
          this.tags.push(item.name)
        })
      })
    },
    getHouseLabel() {
      findByIdHouse(this.houseId).then(res => {
        const label = res.label
        console.log(this.tags)
        console.log(this.selectedTags)
        label !== null && label !== '' ? (this.selectedTags = label.split(',')) : (this.selectedTags = [])
      })
    },
    handleChange(tag, checked) {
      const { selectedTags } = this
      console.log(tag, 'tag')
      console.log(selectedTags.includes(tag), 'selectedTags.includes(tag)')
      if (selectedTags.length >= 10) {
        this.$notification.warning({
          message: this.noticeTitle,
          description: '标签最多10个'
        })
      } else {
        if (!selectedTags.includes(tag)) {
          this.selectedTags = [...this.selectedTags, tag]
        } else {
          this.$notification.warning({
            message: this.noticeTitle,
            description: '标签已存在'
          })
        }
      }
    },
    selectChange(tag, checked) {
      const { selectedTags } = this
      const nextSelectedTags = checked ? [...selectedTags, tag] : selectedTags.filter(t => t !== tag)
      console.log('You are interested in: ', nextSelectedTags)
      this.selectedTags = nextSelectedTags
    },
    // save tag
    saveTag(arr) {
      this.loading = true
      const requestParameters = {
        label: arr.join(',')
      }
      console.log(requestParameters, 'requestParameters')
      editHouses(this.houseId, requestParameters)
        .then(res => {
          this.visible = false
          this.handleLoading = false
          this.loading = false
          this.$message.success(`保存成功`, 2)
          this.load()
        })
        .catch(() => {
          this.handleLoading = false
          this.loading = false
        })
    },
    enterTag(tag) {
      this.currentTag = tag
      console.log(tag)
    },
    addTag() {
      this.mdl = null
      this.visible = true
    },
    handleTagClick(e) {
      const { currentTag, tags, tagsInfo, $message, selectedTags, load, saveTag } = this
      const key = e.key
      switch (key) {
        case 'edit':
          this.mdl = {
            name: currentTag
          }
          this.visible = true
          break
        case 'del':
          this.$confirm({
            title: `要删除${currentTag}?`,
            content: '当点击确认按钮时，这个标签将在1秒后删除',
            onOk() {
              const arr = tags
              const tagIndex = arr.indexOf(currentTag)
              console.log(arr, 'label')
              console.log(tagIndex, 'tagIndex')
              if (tagIndex !== -1) {
                arr.splice(tagIndex, 1)
                console.log(arr, 'new')
                delLabels(tagsInfo[tagIndex].id)
                  .then(res => {
                    const selectIndex = selectedTags.indexOf(currentTag)
                    if (selectIndex !== -1) {
                      selectedTags.splice(selectIndex, 1)
                      saveTag(selectedTags)
                    } else {
                      load()
                      $message.success(`删除成功`, 2)
                    }
                  })
                  .catch(error => {
                    $message.error(`删除失败${error}`, 2)
                  })
              } else {
                $message.error(`标签不存在`, 2)
              }
            },
            onCancel() {}
          })
          break
      }
    },
    handleOk() {
      const { tags, noticeTitle, nortifaction } = this
      const form = this.$refs.createModal.form
      form.validateFields((errors, values) => {
        this.handleLoading = true
        if (tags.includes(values.name)) {
          this.handleLoading = false
          nortifaction(noticeTitle, '标签已存在', 'warning')
          return
        }
        if (!errors) {
          const requestParameters = {
            name: values.name
          }
          if (this.mdl === null) {
            // this.addHandle(requestParameters)
            console.log(tags.includes(values.name), 'tags.includes(values.name)')
            requestParameters.type = 'house'
            addLabels(requestParameters)
              .then(res => {
                this.load()
                this.visible = false
                this.handleLoading = false
                nortifaction(noticeTitle, '添加成功')
              })
              .catch(error => {
                nortifaction(noticeTitle, `${error}`, 'error')
              })
          } else {
            // 新增/修改
            const index = this.tags.indexOf(this.currentTag)
            console.log(index)
            console.log(this.tagsInfo[index], 'this.tagsInfo[index]')
            const id = this.tagsInfo[index].id
            this.editHandle(id, requestParameters)
          }
        } else {
          this.handleLoading = false
        }
      })
    },
    handleCancel(e) {
      const form = this.$refs.createModal.form
      form.resetFields()
      this.visible = false
    },
    editHandle(id, requestParameters) {
      editLabels(id, requestParameters)
        .then(res => {
          const { selectedTags, currentTag, saveTag } = this
          if (selectedTags.includes(currentTag)) {
            const index = selectedTags.indexOf(currentTag)
            selectedTags.splice(index, 1, requestParameters.name)
            saveTag(selectedTags)
          } else {
            this.handleLoading = false
            this.load()
            this.$notification.success({
              message: this.noticeTitle,
              description: '编辑成功'
            })
          }
        })
        .catch(() => {
          this.handleLoading = false
        })
    }
  }
}
</script>

<style lang="less" scoped>
.card {
  /deep/ .tag_title {
    margin-right: 20px;
    width: 150px !important;
    display: inline-block;
    text-align: right;
  }
  /deep/ .ant-tag {
    padding: 5px;
    font-size: 15px;
  }
  .myTag {
    .ant-tag {
      cursor: pointer;
    }
  }
}
</style>
