<template>
  <a-modal
    title="标签"
    :width="modalWidth"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-form-item label="标签名称">
          <a-input v-decorator="['name', {rules: [{ required: true, message: '请输入标签名称' }]}]" :maxLength="10" />
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import { AccessoryUpload } from '@/components'

// 表单字段
const fields = ['name']

export default {
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: () => false
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    },
    media: {
      type: Array,
      default: () => []
    }
  },
  components: {
    AccessoryUpload
  },
  data () {
    return {
      form: this.$form.createForm(this)
    }
  },
  created () {
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))

    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
  },
  methods: {
    onChange (value) {
      console.log(value, 'city')
    }
  }
}
</script>
