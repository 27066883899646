import request from '@/utils/request'

const labelsApi = {
  // get labels info
  labels: '/api/v1/rest/labels',
  findByKeyword: '/api/v1/rest/labels/search/findByKeyword',
  findByHouses_Id: '/rest/labels/search/findByHouses_Id'
}

export function labels (parameter) {
  return request({
    url: labelsApi.labels + `?page=${parameter.page}&size=${parameter.size}&sort=${parameter.sort}`,
    method: 'get'
  })
}

export function addLabels (parameter) {
  return request({
    url: labelsApi.labels,
    method: 'post',
    data: parameter
  })
}

export function editLabels (id, data) {
  return request({
    url: labelsApi.labels + `/${id}`,
    method: 'patch',
    data
  })
}

export function delLabels (id) {
  return request({
    url: labelsApi.labels + `/${id}`,
    method: 'delete'
  })
}

export function findLabels (parameter) {
  return request({
    url: labelsApi.findByKeyword + `?keyword=${parameter.keyword}&page=${parameter.page}&size=${parameter.size}&sort=${parameter.sort}`,
    method: 'get'
  })
}

export function findByIdLabels (parameter) {
  return request({
    url: labelsApi.labels + `/${parameter.id}`,
    method: 'get'
  })
}

export function inLabels (parameter) {
  return request({
    url: labelsApi.labels + `/${parameter.id}/${parameter.name}`,
    method: 'get'
  })
}

export function findByHousesIdLabels (parameter) {
  return request({
    url: labelsApi.findByHouses_Id + `?houseId=${parameter.id}&page=${parameter.page}&size=${parameter.size}&sort=${parameter.sort}`,
    method: 'get'
  })
}

export function findAllByType (parameter) {
  return request({
    url: labelsApi.labels + `/search/findAllByType?type=${parameter.type}`,
    method: 'get'
  })
}
